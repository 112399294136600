.myHeader{
    background-color: #c7c8c9;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.myHeader2{
    background-color: #c7c8c9;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.titulo{
    font-size: 17px;
    font-family: "montserratB";
    color:#464646;
}
