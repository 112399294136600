
.floatButtonProductos{
    position: fixed !important;
    font-size: 22px;
    font-family: 'montserratM';
}
@font-face {
    font-family: "montserratM";
    src: url("../assets/montserrat/Montserrat-Medium.otf");
}
@font-face {
    font-family: "montserratB";
    src: url("../assets/montserrat/Montserrat-Bold.otf");
}
@font-face {
    font-family: "montserratSB";
    src: url("../assets/montserrat/Montserrat-SemiBold.otf");
}
@font-face{
    font-family: 'montserratEB';
    src:url("../assets/montserrat/Montserrat-ExtraBold.otf");
}
@font-face{
    font-family: 'montserratLG';
    src:url("../assets/montserrat/Montserrat-Light.otf");
}
.textMontserratLig{
    font-family: 'montserratLG';
    font-size: 15px;
}
.textMontserratSM{
    font-family: 'montserratSB';
    font-size: 17px;
}
.textInfo{
    font-family: "montserratM";
    font-size: 16px;
}
.textTitle{
    font-family: "montserratB";
    font-size: 16px;
}
.textCard{
    font-family: "montserratM";
    font-size:14px;
}
.textInicio{
    font-family: "montserratEB";
    font-size:16px;
    color: #464646;
}
.textInicioSub{
    font-family: "montserratSB";
    font-size:14px;
    color:#ec7117
}
.selectorRegional{
    background-color: #8d8e8f;
}
.fondoDegradado{
    background: #cccccc
}
.cabeceraTable{
    background-color: #acabab;
    font-size: 16px;
    font-family: "montserratM";
    color: #ffffff !important;
}
.textTable{
    font-family: "montserratM";
    font-size: 15px;
}
.configOverFlow{
    bottom: 20px;
    /*height: 70vh !important;*/
}
.textBs{
    font-family: "montserratSB";
    font-size: 18px;
    color: #ce6778 !important;
}
.fondoInical{
    background:linear-gradient(0deg, rgba(204,204,204,1) 19%, rgba(204,204,204,1) 68%, rgba(255,255,255,1) 100%);
}
.fondoConfig{
    background: #cccccc;
}
.letraSelector{
    color: #c7c8c9;
    font-size: 17px;
    font-family: "montserratB";
}
.botonConfiguration{
    background: #ffffff;
    font-size: 14px;
    font-family: "montserratB";
    color: #464646 !important;
}
.botonConfigurationBack{
    width: 300px;
    background: #8d8e8f;
    font-size: 14px;
    font-family: "montserratSB";
}
.botonPlomo{
    background: #8d8e8f;
    font-size: 14px;
    font-family: "montserratB";
}
.cardColor{
    background: #8d8e8f;
    border-radius: 20px; 
    border:1px solid #8d8e8f;
}
.botonRojo{
    background: #f03d3d;
    font-size: 14px;
    font-family: "montserratB";
}
.botonBlanco{
    background: white;
    font-size: 14px;
    font-family: "montserratB";
    color: #464646 !important;
}
.botonNaranja{
    background: #ec7117;
    font-size: 14px;
    font-family: "montserratB";
    color: #ffffff !important;
}
.overflow-auto.configButton{
    max-height: 70vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.overflow-auto.configInventario{
    max-height: 80vh !important;
}
.verPedidoButon{
    background: #ec7117;
}
.borderLeft{
    background: #fa7210;
    border-bottom-left-radius: 20px !important;
    border-top-left-radius: 20px !important;
    font-size: 15px;
    font-family: "montserratB";
}
.borderRight{
    background: #fa7210;
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
    font-size: 15px;
    font-family: "montserratB";
}
.fondoTransparente{
    background: rgba(83, 83, 83, 0.116) !important;
}
.borderEdit{
    border-radius: 10px;
}
.mapStyle{
    height: 280px;
}
.mapCompleto{
    height: 400px;
}
.selector{
    border: #ffffff;
    background-color: #ffffff;
    border-radius: 5px;
    color: #464646;
}
.form-check-input:checked {
    background-color: #fa7210;
    border-color: #fa7210;
}
.borderSeparador{
    border-left: 2px solid #ec7117;
}

.checketN{
    align-items: center !important;
    display: flex;
}
.leaflet-control-attribution{
    display: none;
}