.itemSi:hover{
    padding-top: 10px;
}
.itemSi{
    background-color: #ffffff;
    color: black;
    font-size: 20px;
    font-family: 'montserratSB';
}
.itemSiSelect{
    padding-left: 15px;
    background-color: #7a7a7a;
    color: black;
    border-radius: 10px;
    font-size: 20px;
    font-family: 'montserratB';
}
.misideBar{
    border: none;
}