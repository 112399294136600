.imgCard{
    height: 150px !important;
    width: 150px !important;
}
.miOverFlow{
    height: 80vh !important;
}
.floatBotones{
    position: fixed !important;
    font-size: 22px;
    font-family: sans-serif;
}