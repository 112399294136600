.miImgCard{
    max-width: 200px;
    max-height: 150px;
}
.textNaranja{
    color: #4d4d4d;
    font-size: 16px;
    font-family: "montserratB";
}
.cardItem{
    border-radius: 3px;
    background: white;
}