.floatButton{
    background: #a5a4a4;
    position: fixed !important;
    font-size: 20px;
    font-family: "montserratSB";
}
.floatButton2{
    background: #fa7210;
    position: fixed !important;
    font-size: 20px;
    font-family: "montserratSB";
}
.letraMontserratM{
    font-size: 22px;
    font-family: "montserratM";
}
.imagenIni{
    background: linear-gradient(45deg, rgba(141,142,143,1) 0%, rgba(251,251,251,1) 52%, rgba(141,142,143,1) 100%);
}
.imagenSe{
    height: 50vh;
    bottom: 0;
    right: 0;
}