.imagenMenu{
    max-width: 220px;
    max-height: 300px;
}
.imgTm{
    max-width: 42px;
    min-width: 18px;
}
.floatBotones{
    position: fixed !important;
    font-size: 22px;
    font-family: "montserratSB";
}
.botones{
    background-color: #ec7117;
    width: 100%;
}
.contenedorCosto{
    background-color: #acabab;
    width: 100%;
}
.botonesContenedor{
    background-color: #8d8d8d;
    width: 100%;
    border-radius: 16px;
}
.botonesContenedor2{
    background-color: #8d8d8d;
    border-radius: 16px;
}
.configOverFlow{
    height: 75vh !important;
}
.botonesback{
    background-color: #c7c8c9;
}
.textButtonL{
    width: 100%;
    font-size: 45px;
    font-family: "montserratB";
    border-right: 3px solid #c7c8c9;
}
.textButtonR{
    width: 100%;
    font-size: 45px;
    font-family: "montserratB";
    border-left: 3px solid #c7c8c9;
}
.textCenterC{
    font-size: 45px;
    font-family: "montserratB";
}