.imgCardVariante{
    max-width: 120px;
    max-height: 120px;
}
.imgCardVarianteG{
    max-width: 230px;
    max-height: 230px;
}
.imgVariante{
    min-width: 26px;
    max-width: 33px;
}
.cardSinBorder{
    background-color: #c7c8c96e;
    border-radius: 10px;
    border: 0px solid rgb(196, 196, 199);
    width: 90%;
}
.recuadro{
    border-radius: 25px;
}
.disminuir{
    width: 100%;
    font-size: 20px;
    font-family: "montserratB";
    border-right: 3px solid #c7c8c9;
}
.aumentar{
    width: 100%;
    font-size: 20px;
    font-family: "montserratB";
    border-left: 3px solid #c7c8c9;
}
.textoCenter{
    font-size: 20px;
    font-family: "montserratB";
}